<template>
  <main class="w-full flex justify-center items-center  mt-5">
    <div class="p-5 w-full lg:w-10/12">
      <header class="flex mb-5 flex-col lg:flex-row justify-between items-center">
        <img
          src="@/assets/03-LogoTJ_horiz_cor_para-fundo-azul.png"
          alt="logo"
          class="lg:w-56"
        />
        <div class="text-white text-2xl flex gap-5">
          <a
            href="https://www.facebook.com/tributojusto/?locale=pt_BR"
            target="_blank"
          >
            <i class="bi bi-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/tributojustocuritiba/mycompany/"
            target="_blank"
          >
            <i class="bi bi-linkedin"></i>
          </a>
          <a href="https://www.instagram.com/tributo_justo/" target="_blank">
            <i class="bi bi-instagram"></i>
          </a>
          <a href="https://tributojusto.com.br/" target="_blank">
            <i class="bi bi-globe2"></i>
          </a>
        </div>
      </header>
      <hr class="mb-5" />

      <div
        class="flex flex-col lg:flex-row lg:justify-between justify-center items-center"
      >
        <div class="w-full lg:w-1/2 text-white">
          <div class="p-2 mt-10">
            <p class="text-4xl">
              A única empresa do Brasil com estrutura para fazer a Recuperação
              de Crédito da folha de pagamento de forma
              <strong>completa</strong> e <strong>segura</strong>.
            </p>
            <p class="w-11/12">
              Projeção desenvolvida estatisticamente com base no histórico dos
              nossos mais de 8 mil clientes.
            </p>
          </div>

          <img
            src="@/assets/tech company-amico.svg"
            class="w-96 mx-auto my-10 lg:my-0"
          />
        </div>

        <form class="w-full lg:w-1/2 h-full p-5 text-white rounded-md">
          <header class="flex justify-between items-center">
            <h2 class="font-medium text-2xl">Estimativa de Recuperação</h2>
            <img src="@/assets/15-SimboloTJ_Negativa.png" alt="" class="w-16" />
          </header>
          <hr />

          <!--  -->
          <div
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            v-if="!loading && estimativa == ''"
          >
            <div class="mt-5 flex flex-col">
              <label for="inputEmpresa" class="ms-2"
                >Empresa: <span class="text-red-600">*</span></label
              >
              <input
                autocomplete="off"
                id="inputEmpresa"
                type="text"
                class="rounded-md p-2 text-blue-tj outline-none focus:shadow-xl"
                placeholder="Digite o nome da empresa"
                v-model="empresa"
              />
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputEmail" class="ms-2"
                >Email: <span class="text-red-600">*</span></label
              >
              <input
                autocomplete="off"
                id="inputEmail"
                type="email"
                class="rounded-md p-2 text-blue-tj outline-none focus:shadow-xl"
                placeholder="Informe o email"
                v-model="email"
              />
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputNomeContato" class="ms-2"
                >Nome: <span class="text-red-600">*</span></label
              >
              <input
                autocomplete="off"
                id="inputNomeContato"
                type="text"
                class="rounded-md p-2 text-blue-tj outline-none focus:shadow-xl"
                placeholder="Informe onome completo"
                v-model="nomeContato"
              />
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputContato" class="ms-2"
                >Telefone: <span class="text-red-600">*</span></label
              >
              <input
                autocomplete="off"
                id="inputContato"
                type="text"
                class="rounded-md p-2 text-blue-tj outline-none focus:shadow-xl"
                placeholder="Ex: (00) 0000-0000"
                v-model="contato"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </div>

            <div class="mt-5 flex gap-10 justify-center">
              <div class="flex">
                <input
                  id="inputLucroReal"
                  type="radio"
                  name="TipoLucro"
                  class="p-2 outline-none focus:shadow-xl cursor-pointer"
                  v-model="TipoLucro"
                  :value="1"
                />

                <label for="inputLucroReal" class="ms-2"
                  >Lucro Real <span class="text-red-600">*</span></label
                >
              </div>

              <div class="flex">
                <input
                  id="inputLucroPresumido"
                  type="radio"
                  name="TipoLucro"
                  class="p-2 outline-none focus:shadow-xl cursor-pointer"
                  v-model="TipoLucro"
                  :value="2"
                />

                <label for="inputLucroPresumido" class="ms-2"
                  >Lucro Presumido <span class="text-red-600">*</span></label
                >
              </div>
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputFuncionarios" class="ms-2"
                >Quantidade de funcionários:
                <span class="text-red-600">*</span></label
              >
              <input
                autocomplete="off"
                id="inputFuncionarios"
                type="number"
                class="rounded-md p-2 text-blue-tj outline-none focus:shadow-xl"
                placeholder="Ex: 100"
                v-model="qtdFuncionario"
              />
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputMediaSalario" class="ms-2"
                >Média de salários: <span class="text-red-600">*</span></label
              >
              <input
                autocomplete="off"
                id="inputMediaSalario"
                type="text"
                class="rounded-md p-2 text-blue-tj outline-none focus:shadow-xl"
                v-model="salarioMedio"
                placeholder="Ex: R$ 1500,00"
                @keyup="mascaraMoeda($event)"
              />
            </div>

            <div class="w-full mt-5 flex justify-center">
              <button
                type="button"
                @click="calcular()"
                class="px-20 py-2 rounded-md bg-blue-800 disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="!validateForm"
              >
                <span class="text-xl font-bold"> Calcular </span>
              </button>
            </div>
          </div>

          <div
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            v-else-if="loading"
            class="mt-5 flex justify-center items-center gap-3 py-64"
          >
            <p class="text-4xl">Calculando</p>
            <div
              class="animate-spin border-4 h-10 w-10 border-t-blue-600/75 rounded-full"
            ></div>
          </div>

          <div
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            v-else
            class="pb-52"
          >
            <h3 class="text-2xl font-bold mt-5">Resultados:</h3>

            <div class="mt-5 flex flex-col">
              <label for="inputMediaSalario">Total de folha:</label>
              <div
                class="p-2 w-full bg-white rounded-md font-semibold text-blue-tj"
              >
                <p class="text-blue-tj">{{ totalFolha || "R$ 0,00" }}</p>
              </div>
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputMediaSalario"
                >Total de impostos (60 meses):</label
              >
              <div
                class="p-2 w-full bg-white rounded-md font-semibold text-blue-tj"
              >
                <p class="text-blue-tj">{{ totalImposto60M || "R$ 0,00" }}</p>
              </div>
            </div>

            <div class="mt-5 flex flex-col">
              <label for="inputMediaSalario">Total a recuperar::</label>
              <div
                class="p-2 w-full bg-white rounded-md font-bold text-blue-tj"
              >
                <p class="text-blue-tj text-4xl">
                  {{ estimativa || "R$ 0,00" }}
                </p>
              </div>
            </div>

            <div class="w-full mt-10 flex justify-center">
              <button
                type="button"
                @click="novoCalculo()"
                class="px-20 py-2 rounded-md bg-blue-800 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span class="text-xl font-bold"> Novo Cálculo </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import axios from "axios";

const qtdFuncionario = ref("");
const salarioMedio = ref("");
const totalFolha = ref("");
const cp = ref("");
const s5 = ref("");
const rat = ref("");
const totalImposto60M = ref("");
const totalImpostoMes = ref("");
const estimativa = ref("");
const baseDeduzida = ref("");
const diferencaImposto = ref("");
const empresa = ref("");
const nomeContato = ref("");
const email = ref("");
const contato = ref("");
const TipoLucro = ref("");
const loading = ref(false);

const validateForm = computed(
  () =>
    qtdFuncionario.value != "" &&
    salarioMedio.value != "" &&
    empresa.value != "" &&
    email.value != "" &&
    nomeContato.value != "" &&
    contato.value != "" &&
    TipoLucro.value != ""
);

watch(totalFolha, (newValue) => {
  if (newValue) {
    const salarioTotalFormat = formatValue(newValue);

    cp.value = salarioTotalFormat * 0.19;
    s5.value = salarioTotalFormat * 0.058;
    rat.value = salarioTotalFormat * 0.031;

    totalImpostoMes.value = cp.value + s5.value + rat.value;

    formatEstimativa();
  }
});

const novoCalculo = () => {
  qtdFuncionario.value = "";
  salarioMedio.value = "";
  totalFolha.value = "";
  cp.value = "";
  s5.value = "";
  rat.value = "";
  totalImposto60M.value = "";
  totalImpostoMes.value = "";
  baseDeduzida.value = "";
  diferencaImposto.value = "";
  estimativa.value = "";
  empresa.value = "";
  nomeContato.value = "";
  email.value = "";
  contato.value = "";
  TipoLucro.value = "";
  loading.value = false;
  return;
};

const calcular = async () => {
  if (validateForm.value) {
    loading.value = true;
    totalFolha.value = calculoSalarioTotal();

    try {
      await axios.post("https://api-estimativa.tributojusto.com.br/api/estimativas", {
        empresa: empresa.value,
        nomeContato: nomeContato.value,
        email: email.value,
        contato: apenasNumeros(contato.value),
        tipoLucro: TipoLucro.value,
        qtdFuncionarios: +qtdFuncionario.value,
        mediaSalario: formatValue(salarioMedio.value),
        totalFolha: formatValue(totalFolha.value),
        totalImposto: formatValue(totalImposto60M.value),
        totalRecuperar: formatValue(estimativa.value),
      });
    } catch (error) {
      console.log(error);
      totalFolha.value = "";
      cp.value = "";
      s5.value = "";
      rat.value = "";
      totalImposto60M.value = "";
      estimativa.value = "";
    } finally {
      loading.value = false;
    }

    return;
  }

  totalFolha.value = "";
  cp.value = "";
  s5.value = "";
  rat.value = "";
  totalImposto60M.value = "";
  estimativa.value = "";
  loading.value = false;
  return;
};

const calculoSalarioTotal = () => {
  if (qtdFuncionario.value != "" && salarioMedio.value != "") {
    let formatSalarioMedio = salarioMedio.value
      .replaceAll(".", "")
      .replace(",", ".");
    return maskMoneyFormat(qtdFuncionario.value * +formatSalarioMedio);
  }

  return maskMoneyFormat();
};

const formatValue = (str) => {
  str = str.replaceAll(".", "");
  str = str.replace(",", ".");
  str = str.replaceAll("R$", "");
  return +str;
};

const formatEstimativa = () => {
  totalImposto60M.value = totalImpostoMes.value * 60;
  baseDeduzida.value = formatValue(totalFolha.value) - totalImpostoMes.value;
  let totalImposto60MCustom = cp.value + s5.value + rat.value;

  cp.value = baseDeduzida.value.toFixed(2) * 0.2;
  s5.value = baseDeduzida.value.toFixed(2) * 0.058;
  rat.value = baseDeduzida.value.toFixed(2) * 0.03;

  totalImposto60M.value = cp.value + s5.value + rat.value;

  diferencaImposto.value = totalImpostoMes.value - totalImposto60M.value;
  diferencaImposto.value = diferencaImposto.value.toFixed(2);

  totalImposto60M.value = maskMoneyFormat(totalImposto60MCustom * 60);
  if (qtdFuncionario.value != "" && salarioMedio.value != "") {
    estimativa.value = diferencaImposto.value * 60;
    estimativa.value = maskMoneyFormat(estimativa.value);
  } else {
    maskMoneyFormat();
  }
};

const maskMoneyFormat = (field = 0) => {
  field = field.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  return field;
};

const mascaraMoeda = () => {
  const valor = salarioMedio.value
    .replace(/[^\d]+/gi, "")
    .split("")
    .reverse()
    .join("");
  let resultado = "";
  var mascara = "##.###.###,##".split("").reverse().join("");
  for (var x = 0, y = 0; x < mascara.length && y < valor.length; ) {
    if (mascara.charAt(x) != "#") {
      resultado += mascara.charAt(x);
      x++;
    } else {
      resultado += valor.charAt(y);
      y++;
      x++;
    }
  }
  salarioMedio.value = resultado.split("").reverse().join("");
};

const apenasNumeros = (str) => {
  return str.replace(/\D/g, "");
};
</script>

<style scoped>
main {
  background-color: #143a84 !important;
}

.text-blue-tj {
  color: #143a84 !important;
}

form {
  background-color: #2bb67399 !important;
}
</style>