<template>
  <Estimativa />
</template>

<script setup>
import Estimativa from './components/Estimativa/Main.vue'
import AOS from "aos";
import "aos/dist/aos.css";
import { onMounted } from 'vue';

onMounted(() => {
  AOS.init();
});
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #143a84 !important;
}
</style>
